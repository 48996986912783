module services {
    export module master {
        export class routeCodeService implements interfaces.master.IRouteCodeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownListByCountry(ownerEntityId: number, isInbound: boolean, loadingCountryId?: number, destinationCountryId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "RouteCode/GetDropdownListByCountry", {
                    ownerEntityId: ownerEntityId,
                    isInbound: isInbound,
                    loadingCountryId: loadingCountryId,
                    destinationCountryId: destinationCountryId
                });
            }

            //Gets list of all rooutes for a dropdown/autocomplete list
            getDropdownListAll(ownerEntityId: number, isInbound?: boolean): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "RouteCode/GetDropdownListAll", {
                    ownerEntityId: ownerEntityId,
                    isInbound: isInbound
                });
            }

            getDropdownListCosting(ownerEntityId: number, isInbound?: boolean): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "RouteCode/GetDropdownListCosting", {
                    ownerEntityId: ownerEntityId,
                    isInbound: isInbound
                });
            }            

            getRouteData(): ng.resource.IResourceClass<interfaces.master.IRouteCode> {

                return this.$resource<interfaces.master.IRouteCode>(this.ENV.DSP_URL + "RouteCode/GetRouteCode", {
                    routeId: '@routeId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false
                        }
                    });
            }
        }
    }

    angular.module("app").service("routeCodeService", services.master.routeCodeService);
}